<template>
  <div
    style="background-color: #303030; padding: 20px"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="3">
          <div
            :class="{ 'pa-5': smAndUp }"
            :style="{ padding: xs ? '0 60px 0 60px' : '' }"
          >
            <v-img src="@/assets/home/Unvergessen-Agentur-4-edit.jpg" style="border-radius: 50%" />
          </div>
        </v-col>
        <v-col cols="12" sm="6" :class="{ 'text-center': xs, 'text-left': smAndUp }">
          <div style="width: 100%; height: 100%">
            <div class="text-white vertical-center" :style="{ 'font-size': smAndUp ? '18px' : '' }" :class="{ 'px-10': smAndUp, ' pt-3': smAndUp }">
              <div class="text-primary-dark mb-2" :class="{ 'text-h4': xs }" :style="{ 'font-size': smAndUp ? '36px' : '' }">
                Philip Pelgen
              </div>
              <div class="mb-6">
                Gründer & Geschäftsführer
              </div>
              
              <div style="cursor: pointer" @click="phone">
                0681 965 938 85
              </div>
              <div class="mb-6" @click="mailTo" style="cursor: pointer">
                p.pelgen@unvergessen-agentur.de
              </div>

              <v-btn class="bg-primary-dark text-white" style="text-transform: none; font-size: 16px" height="50" @click="pushTo('https://calendly.com/unvergessen/agentur-kennenlernen')">
                Termin vereinbaren
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="3" class="text-white text-left">
          <div class="vertical-center text-grey" :style="{ padding: xs ? '20px 0 20px 40px': '' }">
            <v-img src="@/assets/Unvergessen_Agentur_Logo_weiß.png" alt="logo white" max-width="200px" />
            <div style="font-size: 12px">
              <router-link :to="{ name: 'Imprint' }" class="text-grey">Impressum</router-link><br>
              <router-link :to="{ name: 'Privacy' }" class="text-grey">Datenschutzerklärung</router-link>
              <p class="mt-8">
                Gesellschaft für Erinnerungskultur mbH<br>
                Campus A1.1, 66123 Saarbrücken
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { smAndUp, xs } = useDisplay()

    return { smAndUp, xs }
  },
  methods: {
    pushTo (url) {
      this.$ga.event('Footer', 'Click_on_Meeting_Button', this.$route.name, 0)
      window.open(url, '_blank')
    },
    mailTo () {
      window.open('mailto://p.pelgen@unvergessen-agentur.de')
    },
    phone () {
      window.open("tel:+49 681 965 938 85")
    }
  }
}
</script>