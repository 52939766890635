<template>
  <div :class="{ presenter: mdAndUp, 'presenter-m': smAndDown }">
    <div
      :style="{ padding: mdAndUp ? '300px 15%' : xs ? '15px' : '100px' }"
    >
      <div class="text-white text-left" :class="{ 'py-6': smAndDown }">
        <h1 class="font-weight-regular" :class="{ 'text-h2': lgAndUp, 'text-h3': md, 'text-h5': sm, 'text-h6': xs, 'font-weight-medium': smAndDown }">
          Wir machen Ihr Bestattungsinstitut<br>
          zum Marktführer in Ihrer Region
        </h1>
        <p :class="{ 'text-h5': mdAndUp, 'my-8': mdAndUp, 'mt-2': smAndDown, 'mb-10': smAndDown }">
          Deutschlands erste <b>Social Media Agentur</b><br v-if="smAndDown"> für Bestattungsunternehmen
        </p>
        <div :class="{ 'text-center': xs }" :style="{'margin-top': smAndDown ? '120px' : '0px'}">
          <contact-button placement="0" :calendly="true" class="text-text" :width="xs ? '100%' : ''">
            Kostenfreies Kennenlernen vereinbaren
          </contact-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'

export default {
  setup () {
    const { xs, sm, md, lgAndUp, smAndDown, mdAndUp } = useDisplay()

    return { xs, sm, md, lgAndUp, smAndDown, mdAndUp }
  }
}
</script>

<style scoped>
.presenter {
  background-image: url('@/assets/home/Unvergessen_Agentur_Bestatter_Social_Media.jpg');
  background-size: cover;
  background-position: center;
}

.presenter-m {
  background-image: url('@/assets/home/Unvergessen_Agentur_Bestatter_Social_Media_m.jpg');
  background-size: cover;
  background-position: center;
}
</style>
